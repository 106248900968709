import { Component, Vue, Watch } from 'vue-property-decorator';
import SpecificationsDatatable from '@/views/Invoice/SpecificationsDatatable/SpecificationsDatatable.vue';

@Component<Submit>({
  components: {
    SpecificationsDatatable,
  },
})
export default class Submit extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */
  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  public $pageTitle = 'Factuur specificaties indienen';

  protected isCreatingSpecification = false;
  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */

  public mounted() {
    this.emitBreadcrumb();
  }
  // #endregion

  // #region Class methods

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          {
            name: 'Factuur specificaties indienen',
          },
        ],
      });
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */
  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
  // #endregion
}
